import React from 'react'
import { createRoot } from 'react-dom/client'
import { HashRouter as Router } from 'react-router-dom'
import './assets/scss/style.scss'

import * as serviceWorker from './serviceWorker'

// Layout
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import FacebookChat from './components/elements/FacebookChat'
import App from './App'

// updated for ReactDOM ver. 18
// https://efficientuser.com/2022/10/07/reactdom-render-is-no-longer-supported-in-react-18/
// npm install --save history

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <Router>
    <FacebookChat />
    <Header navPosition="right" className="reveal-from-bottom" />
    <main className="site-content">
      <App />
    </main>
    <Footer />
  </Router>
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
