/* eslint-disable react/no-children-prop */
import React, { useRef, useEffect } from 'react'
import { useLocation, Routes, Route } from 'react-router-dom'
import ScrollReveal from './utils/ScrollReveal'
import ReactGA from 'react-ga'

// Layouts
import LayoutDefault from './layouts/LayoutDefault'

// Views
import Home from './views/Home'
// import Events from './views/Events'
import Menu from './views/Menu'
import Locations from './views/Locations'
import Contact from './views/Contact'

// Initialize Google Analytics
// ReactGA.initialize(process.env.REACT_APP_GA_CODE);
ReactGA.initialize('G-XRJ69W0NW1')

const trackPage = (page) => {
  ReactGA.set({ page })
  ReactGA.pageview(page)
}

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<LayoutDefault><Home /></LayoutDefault>} />
    <Route path="/menu" element={<LayoutDefault><Menu /></LayoutDefault>} />
    <Route path="/locations" element={<LayoutDefault><Locations /></LayoutDefault>} />
    <Route path="/contact" element={<LayoutDefault><Contact /></LayoutDefault>} />
  </Routes>
)

// The App component initializes Google Analytics, sets up page tracking, and renders the main application routes with scroll reveal animations.
const App = () => {
  const childRef = useRef()
  const location = useLocation()

  useEffect(() => {
    const page = location.pathname
    document.body.classList.add('is-loaded')
    if (childRef.current) {
      childRef.current.init()
    }
    trackPage(page)
  }, [location])

  return (

    <ScrollReveal ref={childRef}>
      {() => <AppRoutes />}
    </ScrollReveal>

  )
}

export default App
