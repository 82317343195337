/* eslint-disable no-unused-vars */
import React from 'react'
// import sections
import MenuHero from '../components/sections/Menu/MenuHero'
import MenuWraps from '../components/sections/Menu/MenuWraps'
import MenuBurgers from '../components/sections/Menu/MenuBurgers'
import MenuFries from '../components/sections/Menu/MenuFries'
import MenuSnacks from '../components/sections/Menu/MenuSnacks'

const Menu = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })

  return (
    <>
      <MenuHero className="illustration-section-01" />
      {/* <MenuPointers /> */}
      <MenuWraps />
      <MenuBurgers />
      <MenuFries />
      <MenuSnacks />
      {/* <MenuFeaturesTiles /> */}
      {/* <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider />
      <Cta split /> */}
    </>
  )
}

export default Menu
