/* eslint-disable no-unused-vars */
import React from 'react'
// import sections
import ContactHero from '../components/sections/ContactHero'
import ContactFeaturesSplit from '../components/sections/ContactFeaturesSplit'

const Contact = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })

  return (
    <>
      <ContactHero className="illustration-section-01" />
      {/* <FeaturesTiles /> */}
      <ContactFeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      {/* <Testimonial topDivider />
      <Cta split /> */}
    </>
  )
}

export default Contact
