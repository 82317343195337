import React from 'react'
import classNames from 'classnames'
import { SectionTilesProps } from '../../../utils/SectionProps'
import SectionHeader from '../partials/SectionHeader'
import Image from '../../elements/Image'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const MenuSnacks = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  )

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  )

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  )

  const snacksHeader = {
    title: 'Treat yourself',
    paragraph: 'Sweet delicacies made fresh on premises.'
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
      <div className={innerClasses}>
            <SectionHeader data={snacksHeader} className="center-content" />
{/* BEGINNING OF DESSERTS  */}
            <div className={tilesClasses}>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../../assets/images/apple-fritter-icon.png')}
                        alt="Features tile icon 01"
                        width={200}
                        height={126} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Apple Fritter
                    </h4>
                    <p className="m-0 text-sm">
                      Freshly Made.
                    </p>
                  </div>
                </div>
              </div>
            </div>
{/* END OF DESSERTS */}
{/* BEGINNING OF SIDES ROW */}
            <div className={tilesClasses}>
{/* FINGERS AND FRIES */}
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../../assets/images/Fingers-n-Fries.png')}
                        alt="Features tile icon 01"
                        width={300}
                        height={194} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Chicken Fingers n&apos; Fries
                    </h4>
                    <p className="m-0 text-sm">
                      Chicken tenders with a side of hand-cut french fries.
                    </p>
                  </div>
                </div>
              </div>
{/* CHICKEN WINGS */}
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../../assets/images/Chicken-Wings.png')}
                        alt="Features tile icon 01"
                        width={300}
                        height={194} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      1lb Chicken Wings
                    </h4>
                    <p className="m-0 text-sm">
                      Comes with either Buffalo Sauce, Honey Garlic Sauce, or BBQ sauce.
                    </p>
                  </div>
                </div>
              </div>
{/* POGOS */}
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../../assets/images/Corn-Dog.png')}
                        alt="Features tile icon 01"
                        width={300}
                        height={194} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Pogos
                    </h4>
                    <p className="m-0 text-sm">
                    Classic taste of Americana accompanied by our hand-cut fries
                    </p>
                  </div>
                </div>
              </div>
{/* END OF SIDES ROW */}
            </div>
          </div>
      </div>
    </section>

  )
}

MenuSnacks.propTypes = propTypes
MenuSnacks.defaultProps = defaultProps

export default MenuSnacks
