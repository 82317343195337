import React, { useEffect } from 'react' // eslint-disable-line no-unused-vars
// import sections
import Hero from '../components/sections/Hero' // eslint-disable-line no-unused-vars
import FeaturesTiles from '../components/sections/FeaturesTiles' // eslint-disable-line no-unused-vars
import HeroReviews from '../components/sections/HeroReviews' // eslint-disable-line no-unused-vars

const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])
  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesTiles />
      <HeroReviews />
      {/* <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider />
      <Cta split /> */}
    </>
  )
}

export default Home
