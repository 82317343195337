/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import classNames from 'classnames'
import { SectionTilesProps } from '../../../utils/SectionProps'
import SectionHeader from '../partials/SectionHeader'
import Image from '../../elements/Image'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const MenuWraps = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  )

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  )

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  )

  const wrapsHeader = {
    title: 'Main Street Menu',
    paragraph: 'Gigantic wraps to stop your hunger in it\'s tracks.'
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={wrapsHeader} className="center-content" />

{/* BEGINNING OF FIRST ROW */}
{/* BEEF ROLL */}
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../../assets/images/Beef-Roll.png')}
                      alt="Features tile icon 01"
                      width={300}
                      height={194} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Beef Roll
                  </h4>
                  <p className="m-0 text-sm">
                  Our Signature Fried Roll loaded with Grilled Beef Kabob, Cheddar Cheese, Countrystyle Slaw, Spanish Rice, and our own specially blended smashin' Bash Sauce
                  </p>
                </div>
              </div>
            </div>
{/* CHICKEN ROLL */}
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../../assets/images/Chicken-Roll.png')}
                      alt="Features tile icon 02"
                      width={300}
                      height={194} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Chicken Roll
                  </h4>
                  <p className="m-0 text-sm">
                  Our Signature Fried Roll loaded with Roasted Chicken, Cheddar Cheese, Countrystyle Slaw, Spanish Rice, and our own specially blended smashin' Bash Sauce
                  </p>
                </div>
              </div>
            </div>
          </div>
{/* END OF FIRST ROW */}

{/* Southwest Wrap */}
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../../assets/images/Wrap---Southwest.png')}
                      alt="Features tile icon 01"
                      width={300}
                      height={194} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Southwest Wrap
                  </h4>
                  <p className="m-0 text-sm">
                    Our best-selling wrap. Seasoned Crispy Fried Chicken, Crisp Bacon, Cheddar & Mozza, Lettuce, Tomato, Coleslaw, Chipotle Sauce
                  </p>
                </div>
              </div>
            </div>
{/* Texan Wrap */}
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../../assets/images/Wrap---Texan.png')}
                      alt="Features tile icon 02"
                      width={300}
                      height={194} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Texan Wrap
                  </h4>
                  <p className="m-0 text-sm">
                    Fresh Canadian Beef. No Filler. No Preservatives. Smoked Bacon. St Albert Cheddar Cheese. Hickory BBQ Sauce.
                  </p>
                </div>
              </div>
            </div>
{/* Dynamite Wrap */}
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../../assets/images/Wrap---Dynamite-Chicken.png')}
                      alt="Features tile icon 03"
                      width={300}
                      height={181} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Dynamite Wrap
                  </h4>
                  <p className="m-0 text-sm">
                    Specially Seasoned Crispy Fried Chicken, Lettuce, Tomato, Coleslaw Salad, Crispy Jalapenos, Creamy Garlic Parm Sauce and Sweet Thai Chilli Sauce
                  </p>
                </div>
              </div>
            </div>
          </div>
{/* END OF SECOND ROW */}

{/* BEGINNING OF THIRD ROW */}
{/* HOT HONEY GARLIC WRAP */}
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../../assets/images/Honey-Garlic.png')}
                      alt="Features tile icon 04"
                      width={300}
                      height={194} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Hot Honey Garlic Wrap
                  </h4>
                  <p className="m-0 text-sm">
                  Crispy Chicken, Bacon, Cheddar & Mozza, Lettuce, Tomato, Coleslaw, Honey Garlic Sauce
                  </p>
                </div>
              </div>
            </div>
{/* Sweet Onion Wrap */}
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../../assets/images/Wrap---Crispy-Onion.png')}
                      alt="Features tile icon 05"
                      width={300}
                      height={167} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Crispy Onion Wrap
                  </h4>
                  <p className="m-0 text-sm">
                    Perfectly Seasoned Beef, Lettuce, Tomato, Homestyle Coleslaw, Cajun Fried Onion Strings, Horseradish Sauce & Sweet Onion Sauce
                  </p>
                </div>
              </div>
            </div>
{/* Sweet Heat Wrap */}
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../../assets/images/Wrap---Sweet-Heat.png')}
                      alt="Features tile icon 06"
                      width={300}
                      height={194} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Sweet Heat Wrap
                  </h4>
                  <p className="m-0 text-sm">
                    Crispy Chicken. Coleslaw. Crispy Jalapenos. Sweet and Spicy Sauce.
                  </p>
                </div>
              </div>
            </div>

{/* END OF THIRD ROW */}

{/* BEGINNING OF FOURTH ROW */}
{/* BUFFALO CHICKEN WRAP */}
<div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../../assets/images/Buffalo.png')}
                      alt="Features tile icon 04"
                      width={300}
                      height={194} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Buffalo Chicken Wrap
                  </h4>
                  <p className="m-0 text-sm">
                  Crispy Chicken, Cheddar & Mozza, Lettuce, Tomato, Coleslaw, Ranch Sauce & Buffalo Sauce
                  </p>
                </div>
              </div>
            </div>
{/* Chicken Garlic Parm Wrap */}
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../../assets/images/Grilled-Chicken-Club.png')}
                      alt="Features tile icon 05"
                      width={300}
                      height={194} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Grilled Chicken Club Wrap
                  </h4>
                  <p className="m-0 text-sm">
                  Grilled Chicken, Crispy Bacon, Cheddar & Mozza & Parmesan, Lettuce, Tomato, Coleslaw, Mayo
                  </p>
                </div>
              </div>
            </div>
{/* Chipotle Jalapeno Relish Wrap */}
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../../assets/images/Wrap---Chipotle-Jalapeno.png')}
                      alt="Features tile icon 06"
                      width={300}
                      height={194} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Chipotle Jalapeno Relish Wrap
                  </h4>
                  <p className="m-0 text-sm">
                    Roasted Chicken Breast, Bacon, Cheddar & Mozza, Lettuce, Tomato,
                    Coleslaw, Jalapeno Relsih, Chipotle Sauce
                  </p>
                </div>
              </div>
            </div>
{/* END OF FOURTH ROW */}

{/* BEGINNING OF FIFTH ROW */}
{/* B.O.M. Wrap */}
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../../assets/images/Wrap---Beef-Mushroom-Onion-Garlic-Parm-Wrap.png')}
                      alt="Features tile icon 04"
                      width={300}
                      height={194} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    B.O.M. Wrap
                  </h4>
                  <p className="m-0 text-sm">
                    Fresh Canadian Beef. No Filler. No Preservatives. Cheddar & Mozza,
                    Lettuce, Tomato, Coleslaw, Parmesan, Crispy Mushroom & Onion, Garlic Parm Sauce
                  </p>
                </div>
              </div>
            </div>
{/* Chicken Garlic Parm Wrap */}
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../../assets/images/Wrap---Garlic-Parm-Wrap.png')}
                      alt="Features tile icon 05"
                      width={300}
                      height={194} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Chicken Garlic Parm Wrap
                  </h4>
                  <p className="m-0 text-sm">
                    Roasted Chicken Breast , Cheddar & Mozza & Parmesan,
                    Lettuce, Tomato, Coleslaw, Garlic Parm Sauce
                  </p>
                </div>
              </div>
            </div>
{/* END OF FIFTH ROW */}

          </div>
        </div>
      </div>
    </section>

  )
}

MenuWraps.propTypes = propTypes
MenuWraps.defaultProps = defaultProps

export default MenuWraps
